import * as React from "react"
import Container from "@mui/material/Container";
import {Box, Grid} from "@mui/material";
import {StaticImage} from "gatsby-plugin-image";
import Typography from "@mui/material/Typography";
import {sectionGridSpacing, sectionPadding} from "../theme";
import {Slogan} from "../components/typography";

export default () => {
    return (
        <article>
            <StaticImage
                src={'../images/sfeer/GettyImages-529341407.jpg'}
                alt={'water'}
                layout={'fullWidth'}
                aspectRatio={1370/405}
                transformOptions={{
                    cropFocus: 'center'
                }}
            />
            <Box
                marginTop={10}
            >
                <Container>
                    <Grid
                        container
                    >
                        <Grid item xs={12} md={7}>
                            <Box
                                marginBottom={2}
                            >
                                <Slogan>
                                    Keuken om van te dromen
                                </Slogan>
                            </Box>
                            <Typography variant={'h2'}>
                                Smaakvol kiezen
                            </Typography>
                            <Typography paragraph>
                                Mijn keuken, mijn smaak! En zo is dat. Kwestie van uw eigen ingrediënten toevoegen. Mag het strak, modern of juist klassiek? Wordt het een kookeiland of heel minimalistisch? Bij de ontwikkeling van Daverhof is er in de basis voor gekozen om geen keuken mee te nemen.
                            </Typography>
                            <Typography paragraph>
                                De smaken hierin zijn zo divers dat we deze keuze geheel aan u overlaten. Samen met de projectleverancier kunt u uw droomkeuken samenstellen. Deze zorgt ervoor dat deze geheel naar wens wordt geplaatst.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <StaticImage
                                src={'../images/sfeer/Duurzaam-1.jpg'}
                                alt={'terras'}
                                aspectRatio={474/380}
                                layout={'fullWidth'}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box
                paddingY={sectionPadding}
            >
                <Container>
                    <Grid
                        container
                        spacing={sectionGridSpacing}
                        direction={'row-reverse'}
                    >
                        <Grid
                            item
                            xs={12}
                            md={5}
                        >
                            <Box marginBottom={2}>
                                <Slogan>
                                    Heerlijk, uw eigen badkamer
                                </Slogan>
                            </Box>
                            <Typography
                                color={'primary.main'}
                                variant={'h2'}
                            >
                                Genieten maar
                            </Typography>
                            <Typography paragraph>
                                Heerlijk even de boel de boel laten in je badkamer. Hoe gaat ‘ie eruit zien? Het toilet op de begane grond en de badkamer boven worden compleet opgeleverd met tegelwerk en sanitair. Standaard is de badkamer voorzien van een vrijhangend toilet, wastafel, radiator en douche-opstelling.
                            </Typography>
                            <Typography paragraph>
                                Houdt u van uitgebreid badderen? Upgrade de badkamer dan met een luxe ligbad voor extra ontspanningsmomenten.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <StaticImage
                                src={'../images/sfeer/Timo-Sorber_86A4636.jpg'}
                                alt={'eiland van maurik'}
                                layout={'fullWidth'}
                                aspectRatio={572/402}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </article>
    )
}
