import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";

export const Slogan = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontFamily: 'Rajdhani',
    display: 'inline',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    textAlign: 'left',
    boxDecorationBreak: 'clone',
    webkitBoxDecorationBreak: 'clone',
    marginBottom: 16
}));
